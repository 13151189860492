export const environment = {
  environmentName: 'staging',
  production: false,
  ENDPOINTS: {
    API_AUTH: 'https://auth-api.inapp.s.vitamincode.id',
    API_MAIN: 'https://cod-api.inapp.s.vitamincode.id',
    API_AUTHV1: 'https://auth-api.inapp.s.vitamincode.id/v1',
    API_MAINV1: 'https://cod-api.inapp.s.vitamincode.id/v1',
    API_COD_V1: 'https://cod-api.inapp.s.vitamincode.id/v1',
    API_BILLING_V1: 'https://billing-api.inapp.s.vitamincode.id/v1',
    API_SALES_V1: 'https://billing-api.inapp.s.vitamincode.id/v1',
    API_MAIN_CS: 'https://monitoring-awb-http.core.cs.d.vitamincode.id',
    API_MASTER_DATA_CS:
      'https://monitoring-awb-http.core.cs.d.vitamincode.id/master-data',
  },
};
