/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { GuardRoute } from '@clodeo-internal-finance/components/guard-route/guard-route.component';
import { MainLayoutComponent } from '@clodeo-internal-finance/components/layout-component/main/main-layout.component';
import { CsRouter } from '@clodeo-internal-finance/cs-web/cs-web-router';
import { SalesRouter } from '@clodeo-internal-finance/sales-web/sales-router';
import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';
import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

const AWBNotFoundDetailComponent = React.lazy(
  () => import('./awb-not-found/detail'),
);
const AWBNotFoundCodListComponent = React.lazy(
  () => import('./awb-not-found/list'),
);
const CourierListComponent = React.lazy(() => import('./courier/list'));
const DisbursementApprovedDetailAWBComponent = React.lazy(
  () => import('./disbursement-approved/detail-awb'),
);
const DisbursementApprovedDetailComponent = React.lazy(
  () => import('./disbursement-approved/detail'),
);
const DisbursementApprovedListComponent = React.lazy(
  () => import('./disbursement-approved/list'),
);
const MonitoringAWBDetailComponent = React.lazy(
  () => import('./monitoring-awb/detail'),
);
const MonitoringAWBListComponent = React.lazy(
  () => import('./monitoring-awb/list'),
);
const AmendmentCodFeeListComponent = React.lazy(
  () => import('./amendment-cod-fee/list'),
);
const ReceiptsDeletedDetailComponent = React.lazy(
  () => import('./receipts-deleted/detail'),
);
const ReceiptsDeletedListComponent = React.lazy(
  () => import('./receipts-deleted/list'),
);
const ReceiptsReadyToBeCashedDetailComponent = React.lazy(
  () => import('./receipts-ready-to-be-cashed/detail'),
);
const ReceiptsReadyToBeCashedListComponent = React.lazy(
  () => import('./receipts-ready-to-be-cashed/list'),
);
const SellerDetailComponent = React.lazy(() => import('./seller/detail'));
const SellerListComponent = React.lazy(() => import('./seller/list'));
const SellerUpdateComponent = React.lazy(() => import('./seller/update'));
const UsersListComponent = React.lazy(() => import('./users/users/list'));
const UserPermissionListComponent = React.lazy(
  () => import('./users/permissions/list'),
);
const UserRolesListComponent = React.lazy(
  () => import('./users/role-rf/list/role-rf-list.component'),
);
const MonitoringAllComponent = React.lazy(
  () => import('./monitoring-all/list'),
);
const InvoicePaymentListComponent = React.lazy(
  () => import('./payment/invoice/list'),
);

const SettingClodeoComponent = React.lazy(
  () => import('./billing/settings/clodeo-setting/list'),
);
const SettingShipdeoComponent = React.lazy(
  () => import('./billing/settings/shipdeo-setting/list'),
);
const AWBNotFoundBillingListComponent = React.lazy(
  () => import('./billing/awb-not-found-billing/list'),
);
const MonitoringAWBBillingComponent = React.lazy(
  () => import('./billing/monitoring-awb/list'),
);
const ProformaInvoiceListComponent = React.lazy(
  () => import('./billing/proforma-invoice/list'),
);
const DetailProformaInvoiceComponent = React.lazy(
  () => import('./billing/proforma-invoice/detail'),
);
const DetailAWBBillingComponent = React.lazy(
  () => import('./billing/monitoring-awb/detail'),
);
const InvoiceBillingListComponent = React.lazy(
  () => import('./billing/invoice/list'),
);
const DetailInvoiceComponent = React.lazy(
  () => import('./billing/invoice/detail'),
);
const ListAwbSellerComponent = React.lazy(
  () => import('./billing/monitoring-awb/list-awb-seller/'),
);
const AWBNotFoundBillingDetailComponent = React.lazy(
  () => import('./billing/awb-not-found-billing/detail'),
);
const ReceiptsDeletedListBillingComponent = React.lazy(
  () => import('./billing/receipts-deleted/list'),
);
const ReceiptsDeletedDetailBillingComponent = React.lazy(
  () => import('./billing/receipts-deleted/detail'),
);
const AdjustmentAWBNewInvoicesListComponent = React.lazy(
  () => import('./adjustment/awb/new-invoices/list'),
);
const AdjustmentAWBNewInvoiceDetailComponent = React.lazy(
  () => import('./adjustment/awb/new-invoices/detail'),
);
const AdjustmentAWBCreditNotesListComponent = React.lazy(
  () => import('./adjustment/awb/credit-notes/list'),
);
const AdjustmentAWBCreditNotesDetailComponent = React.lazy(
  () => import('./adjustment/awb/credit-notes/detail'),
);
const AdjustmentAWBDebitNotesListComponent = React.lazy(
  () => import('./adjustment/awb/debit-notes/list'),
);
const AdjustmentAWBDebitNotesDetailComponent = React.lazy(
  () => import('./adjustment/awb/debit-notes/detail'),
);
const AdjustmentInvoicesListComponent = React.lazy(
  () => import('./adjustment/invoices/list'),
);
const AdjustmentInvoicesDetailComponent = React.lazy(
  () => import('./adjustment/invoices/detail'),
);
const AdjustmentCreditNotesListComponent = React.lazy(
  () => import('./adjustment/credit-notes/list'),
);
const AdjustmentCreditNotesDetailComponent = React.lazy(
  () => import('./adjustment/credit-notes/detail'),
);
const AdjustmentProformaListComponent = React.lazy(
  () => import('./adjustment/proforma/list'),
);
const AdjustmentProformaDetailComponent = React.lazy(
  () => import('./adjustment/proforma/detail'),
);
const PaymentHistoryListComponent = React.lazy(
  () => import('./payment/history/list'),
);
const ProfileConfigurationListComponent = React.lazy(
  () => import('./billing/profile-configuration/list'),
);
const ProfileConfigurationCreateComponent = React.lazy(
  () => import('./billing/profile-configuration/create'),
);
const ProfileConfigurationUpdateComponent = React.lazy(
  () => import('./billing/profile-configuration/update'),
);

const DiscountComponent = React.lazy(() => import('./discount'));

export function MainRouter() {
  const { path, url } = useRouteMatch();

  const CodRouter = () => {
    return (
      <Switch>
        <GuardRoute
          path={`${url}/cod/monitoring-awb`}
          exact
          meta={{
            permissions: ['cod.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <MonitoringAWBListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/monitoring-awb/:id`}
          exact
          meta={{
            permissions: ['cod.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <MonitoringAWBDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/receipts-ready-to-be-cashed`}
          exact
          meta={{
            permissions: ['cod.awb-ready-to-cashout.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsReadyToBeCashedListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/receipts-ready-to-be-cashed/:id`}
          exact
          meta={{
            permissions: ['cod.awb-ready-to-cashout.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsReadyToBeCashedDetailComponent />
        </GuardRoute>
        {/* <GuardRoute
          path={`${url}/cod/receipts-deleted/active`}
          exact
          meta={{
            permissions: ['cod.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedListComponent />
        </GuardRoute> */}
        <GuardRoute
          path={`${url}/cod/receipts-deleted/not-found`}
          exact
          meta={{
            permissions: ['cod.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/receipts-deleted/:id`}
          exact
          meta={{
            permissions: ['cod.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/disbursement-approved`}
          exact
          meta={{
            permissions: ['cod.approved-disbursement.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DisbursementApprovedListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/disbursement-approved/list-receipt`}
          exact
          meta={{
            permissions: ['cod.approved-disbursement.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DisbursementApprovedListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/disbursement-approved/list-return-receipt`}
          exact
          meta={{
            permissions: ['cod.approved-disbursement.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DisbursementApprovedListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/disbursement-approved/:id`}
          exact
          meta={{
            permissions: ['cod.approved-disbursement.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DisbursementApprovedDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/disbursement-approved/:id/awb/:idAwb`}
          exact
          meta={{
            permissions: ['cod.approved-disbursement.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DisbursementApprovedDetailAWBComponent />
        </GuardRoute>
        {/* <GuardRoute
          path={`${url}/cod/awb-not-found`}
          exact
          meta={{
            permissions: ['cod.awb-not-found.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AWBNotFoundCodListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/cod/awb-not-found/:id`}
          exact
          meta={{
            permissions: ['cod.awb-not-found.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AWBNotFoundDetailComponent />
        </GuardRoute> */}
      </Switch>
    );
  };

  const GeneralRouter = () => {
    return (
      <Switch>
        <GuardRoute
          path={`${url}/courier`}
          exact
          meta={{
            permissions: ['setting.courier.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <CourierListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/users`}
          exact
          meta={{
            permissions: ['setting.user.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <UsersListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/users/permissions`}
          exact
          meta={{
            permissions: ['setting.permission.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <UserPermissionListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/users/access-roles`}
          exact
          meta={{
            permissions: ['setting.role.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <UserRolesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/monitoring-awb`}
          exact
          meta={{
            permissions: [
              'monitoring-awb.monitoring-awb.view',
              'cod.monitoring-awb.view',
              'billing.monitoring-awb.view',
              'monitoring-cs.monitoring-awb.view',
            ],
            module: [
              'finance-apps-clodeo',
              'finance-apps-shipdeo',
              'customer-service',
            ],
          }}
        >
          <MonitoringAllComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/perubahan-diskon`}
          exact
          meta={{
            permissions: ['setting.approval-discount.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DiscountComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/amendment-cod-fee`}
          exact
          meta={{
            permissions: ['setting.approval-cod-fee.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AmendmentCodFeeListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/invoice-payment`}
          exact
          meta={{
            permissions: ['payment.invoice-payment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <InvoicePaymentListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/payment-history`}
          exact
          meta={{
            permissions: ['payment.payment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <PaymentHistoryListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/setting-clodeo`}
          exact
          meta={{
            permissions: ['setting.setting.clodeo.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <SettingClodeoComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/setting-shipdeo`}
          exact
          meta={{
            permissions: ['setting.setting.shipdeo.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <SettingShipdeoComponent />
        </GuardRoute>

        <GuardRoute
          path={`${url}/profile-configuration/list`}
          exact
          meta={{
            permissions: ['setting.sla-return-profile-configuration.view'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProfileConfigurationListComponent />
        </GuardRoute>

        <GuardRoute
          path={`${url}/profile-configuration/list/create`}
          exact
          meta={{
            permissions: ['setting.sla-return-profile-configuration.create'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProfileConfigurationCreateComponent />
        </GuardRoute>

        <GuardRoute
          path={`${url}/profile-configuration/list/update/:id`}
          exact
          meta={{
            permissions: ['setting.sla-return-profile-configuration.edit'],
            module: ['setting', 'finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProfileConfigurationUpdateComponent />
        </GuardRoute>
      </Switch>
    );
  };

  const BillingRouter = () => {
    return (
      <Switch>
        <GuardRoute
          path={`${url}/billing/monitoring/awb`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <MonitoringAWBBillingComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/monitoring/awb/:id`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DetailAWBBillingComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/monitoring/awb/:id/list`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ListAwbSellerComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/monitoring/return`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb-return.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <MonitoringAWBBillingComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/monitoring/return/:id`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb-return.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DetailAWBBillingComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/monitoring/return/:id/list`}
          exact
          meta={{
            permissions: ['billing.monitoring-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ListAwbSellerComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/invoice/active`}
          exact
          meta={{
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <InvoiceBillingListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/invoice/hold-return`}
          exact
          meta={{
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <InvoiceBillingListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/invoice/hold`}
          exact
          meta={{
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <InvoiceBillingListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/invoice/return`}
          exact
          meta={{
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <InvoiceBillingListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/invoice/:id`}
          exact
          meta={{
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DetailInvoiceComponent />
        </GuardRoute>
        {/* <GuardRoute
          path={`${url}/billing/awb-not-found`}
          exact
          meta={{
            permissions: ['billing.awb-not-found.view'],
            module: ['finance-apps-clodeo'],
          }}
        >
          <AWBNotFoundBillingListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/awb-not-found/:id`}
          exact
          meta={{
            permissions: ['billing.awb-not-found.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AWBNotFoundBillingDetailComponent />
        </GuardRoute> */}
        <GuardRoute
          path={`${url}/billing/proforma-invoice/active`}
          exact
          meta={{
            permissions: ['billing.proforma.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProformaInvoiceListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/proforma-invoice/hold`}
          exact
          meta={{
            permissions: ['billing.proforma.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProformaInvoiceListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/proforma-invoice/hold-return`}
          exact
          meta={{
            permissions: ['billing.proforma.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ProformaInvoiceListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/proforma-invoice/:id`}
          exact
          meta={{
            permissions: ['billing.proforma.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <DetailProformaInvoiceComponent />
        </GuardRoute>
        {/* <GuardRoute
          path={`${url}/billing/receipts-deleted/active`}
          exact
          meta={{
            permissions: ['billing.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedListBillingComponent />
        </GuardRoute> */}
        <GuardRoute
          path={`${url}/billing/receipts-deleted/not-found`}
          exact
          meta={{
            permissions: ['billing.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedListBillingComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/billing/receipts-deleted/:id`}
          exact
          meta={{
            permissions: ['billing.deleted-awb.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <ReceiptsDeletedDetailBillingComponent />
        </GuardRoute>
      </Switch>
    );
  };

  const AdjusmentRouter = () => {
    return (
      <Switch>
        <GuardRoute
          path={`${url}/adjustment/awb/credit-notes`}
          exact
          meta={{
            permissions: ['adjustment.adjustment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBCreditNotesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/awb/credit-notes/:id`}
          exact
          meta={{
            permissions: ['adjustment.adjustment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBCreditNotesDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/awb/new-invoices/`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBNewInvoicesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/awb/new-invoices/:id`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBNewInvoiceDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/awb/debit-notes/`}
          exact
          meta={{
            permissions: ['adjustment.adjustment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBDebitNotesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/awb/debit-notes/:id`}
          exact
          meta={{
            permissions: ['adjustment.adjustment.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentAWBDebitNotesDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/proforma/invoices/:id`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentProformaDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/proforma/invoices`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentProformaListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/proforma/awb-on-hold`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentProformaListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/credit-notes/active`}
          exact
          meta={{
            permissions: ['adjustment.credit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentCreditNotesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/credit-notes/:id`}
          exact
          meta={{
            permissions: ['adjustment.credit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentCreditNotesDetailComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/invoices/active`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentInvoicesListComponent />
        </GuardRoute>
        <GuardRoute
          path={`${url}/adjustment/invoices/:id`}
          exact
          meta={{
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo', 'finance-apps-shipdeo'],
          }}
        >
          <AdjustmentInvoicesDetailComponent />
        </GuardRoute>
      </Switch>
    );
  };

  const SalesWebRouter = () => {
    return <SalesRouter />;
  };

  const CsWebRouter = () => {
    return <CsRouter />;
  };

  return (
    <>
      <MainLayoutComponent>
        <Suspense fallback={<LazyPageComponent />}>
          <CodRouter />
          <GeneralRouter />
          <BillingRouter />
          <SalesWebRouter />
          <CsWebRouter />
          <AdjusmentRouter />
        </Suspense>
      </MainLayoutComponent>
    </>
  );
}
